import * as React from "react";
import classnames from "classnames";

import type { ProgramGoalFragment } from "@/gqlgen";
import type Goal from "@/enums/goal";
import Routes from "@/enums/routes";
import Query from "@/enums/query";
import { images } from "@/enums/goal";
import LoaderBox from "components/LoaderBox";
import NameGoal from "components/NameGoal";
import Link from "components/Link";
import Image from "components/Image";

export function Loader() {
  return <LoaderBox className="h-20 w-32 rounded-xl" />;
}

type Props = {
  className?: string;
  data: ProgramGoalFragment;
};

export default function ProgramGoal({ className, data }: Props) {
  const url = React.useMemo(
    () => ({
      pathname: Routes.SEARCH,
      query: {
        [Query.GOAL]: data.code,
      },
    }),
    [data.code],
  );

  return (
    <Link
      href={url}
      className={classnames(
        className,
        "group block relative overflow-hidden h-20 w-32 rounded-xl text-alt text-xl hover:text-ink-background-hover",
      )}
      aria-label="program goal"
    >
      {data.code in images && (
        <Image
          imageId={images[data.code as Goal]}
          height={80}
          width={128}
          priority
          className="absolute inset-0 object-cover transition-all brightness-50 group-hover:scale-110 group-hover:brightness-75"
        />
      )}

      <div className="absolute inset-0 flex items-center justify-center">
        <span className="font-alt font-bold italic uppercase">
          <NameGoal code={data.code} />
        </span>
      </div>
    </Link>
  );
}
