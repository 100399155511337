/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import { FiSearch } from "react-icons/fi";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";
import { useUID } from "react-uid";

const messages = defineMessages({
  placeholder: {
    defaultMessage: "Search for Programs, Exercises, ...",
  },
});

type Props = React.InputHTMLAttributes<HTMLInputElement>;

function SearchInput({ className, ...input }: Props, ref: React.Ref<HTMLInputElement>) {
  const id = useUID();
  const intl = useIntl();

  return (
    <label className={classnames(className, "block relative px-4 cursor-pointer")} htmlFor={id}>
      <input
        className="h-9 w-full px-4 rounded-xl bg-neutral-white text-neutral-dark text-sm appearance-none outline-none focus:outline-none placeholder:text-neutral-dark placeholder:opacity-20 placeholder:text-sm"
        {...input}
        id={id}
        placeholder={intl.formatMessage(messages.placeholder)}
        autoComplete="off"
        ref={ref}
      />
      <FiSearch className="icon-sm absolute top-2.5 right-6 text-neutral-dark" />
    </label>
  );
}

export default React.forwardRef(SearchInput);
