import * as React from "react";
import { useQuery } from "urql";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import classnames from "classnames";
import type { GetServerSidePropsContext } from "next";

import styles from "./index.module.css";

import { getSSRData, getSSRSession } from "server/ssr";
import { withClient } from "@/services/client";
import { IndexQueryDocument } from "@/gqlgen";
import Navbar from "@/modules/nav/components/Navbar";
import Cookies from "@/modules/account/components/Cookies";
import GainWagon from "components/GainWagon";
import Menu from "@/modules/nav/components/Menu";
import SearchInput from "@/modules/search/components/SearchInput";
import SideScroll from "components/SideScroll";
import Hashtag, { Loader as LoaderHashtag } from "@/modules/search/components/Hashtag";
import ProgramFeatured, {
  Loader as LoaderProgramFeatured,
} from "@/modules/search/components/ProgramFeatured";
import ProgramGoal, { Loader as LoaderProgramGoal } from "@/modules/search/components/ProgramGoal";
import ProgramResult, { Loader as LoaderProgramResult } from "components/ProgramResult";
import Footer from "@/modules/nav/components/Footer";
import { text } from "@/services/intl/embed";
import Link from "components/Link";
import Routes from "@/enums/routes";
import BadgeProfile from "@/modules/account/components/BadgeProfile";
import Query from "@/enums/query";
import Action from "@/enums/action";
import Error from "components/Error";
import { useAuthChange } from "@/modules/account/services/context";

function Index() {
  const router = useRouter();
  const [res, refetchQuery] = useQuery({
    query: IndexQueryDocument,
  });

  useAuthChange(() => {
    refetchQuery();
  });

  const handleSearch = React.useCallback(async () => {
    await router.push({
      pathname: Routes.SEARCH,
      query: { [Query.ACTION]: Action.SEARCH },
    });
  }, [router]);

  return (
    <>
      <Navbar
        title={<GainWagon className="text-3xl" color="inverse" />}
        actionLeft={<Menu />}
        actionRight={<BadgeProfile color="product" />}
      >
        <div className="px-4 my-4 text-ink-product">
          <h1 className="heading-1">
            <FormattedMessage defaultMessage="Time to TRAIN! 🚂" />
          </h1>
          <span className="font-main">
            <FormattedMessage defaultMessage="Training programs for all your goals" />
          </span>
        </div>

        <SearchInput className="my-2" name="autocomplete" readOnly onFocus={handleSearch} />

        <SideScroll space="space-x-4">
          {res.fetching && (
            <>
              <LoaderHashtag />
              <LoaderHashtag />
              <LoaderHashtag />
            </>
          )}

          {res.data?.categories?.map(
            (node) => node !== null && <Hashtag key={node.code} data={node} />,
          )}
        </SideScroll>
      </Navbar>

      <main className={classnames(styles.main, "mb-8")}>
        {res.error && <Error className="px-4 py-1" />}

        <SideScroll>
          {res.fetching && (
            <>
              <LoaderProgramFeatured />
              <LoaderProgramFeatured />
            </>
          )}

          {res.data?.featured?.map(
            (node) => node !== null && <ProgramFeatured key={node.slug} data={node} />,
          )}
        </SideScroll>

        <h2 className="font-alt text-2xl leading-loose font-thin px-4">
          <FormattedMessage
            defaultMessage="Training <b>Goals</b>"
            values={{
              b: text({ className: "font-bold" }),
            }}
          />
        </h2>

        <SideScroll>
          {res.fetching && (
            <>
              <LoaderProgramGoal />
              <LoaderProgramGoal />
              <LoaderProgramGoal />
              <LoaderProgramGoal />
            </>
          )}

          {res.data?.goals?.map(
            (node) => node !== null && <ProgramGoal key={node.code} data={node} />,
          )}
        </SideScroll>

        <div className="flex items-baseline justify-between px-4">
          <h2 className="font-alt text-2xl leading-loose my-2 font-thin">
            <FormattedMessage
              defaultMessage="Training <b>Programs</b>"
              values={{
                b: text({ className: "font-bold" }),
              }}
            />
          </h2>

          <Link className="text-sm" href={Routes.SEARCH}>
            <FormattedMessage defaultMessage="View All" />
          </Link>
        </div>

        <div className="px-4 space-y-3 mb-3">
          {res.fetching && (
            <>
              <LoaderProgramResult />
              <LoaderProgramResult />
            </>
          )}

          {res.data?.popular?.map(
            (node) => node != null && <ProgramResult key={node.id} data={node} />,
          )}
        </div>
      </main>

      <Cookies />

      <Footer />
    </>
  );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const session = await getSSRSession(ctx);
  if (session == null) {
    ctx.res.setHeader("Cache-Control", "public, s-maxage=7200, stale-while-revalidate=360000");
  }

  const urqlState = await getSSRData({ document: IndexQueryDocument, session });

  return {
    props: { session, urqlState },
  };
}

export default withClient(Index);
