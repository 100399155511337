import * as React from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import { useMountedState } from "react-use";

import styles from "./Sidebar.module.css";

import SidebarContent from "@/modules/nav/components/SidebarContent/dynamic";
import Layout from "@/enums/layout";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function Sidebar({ open, onClose }: Props) {
  const isMounted = useMountedState();

  if (!isMounted()) {
    return null;
  }

  return createPortal(
    <CSSTransition in={open} timeout={200} unmountOnExit>
      <div id={Layout.SIDEBAR} className="fixed inset-0 z-sidebar">
        <section
          className={classnames(
            styles.container,
            "absolute top-0 left-0 w-[300px] h-screen pt-20 pl-6 pr-3 bg-product transition-transform",
          )}
        >
          <SidebarContent onClose={onClose} />
        </section>
        <div
          className={classnames(
            styles.overlay,
            "absolute inset-0 bg-background backdrop-blur transition-opacity opacity-0 z-background",
          )}
        />
      </div>
    </CSSTransition>,
    document.body,
  );
}
