/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import classnames from "classnames";

import type { ProgramFeaturedFragment } from "@/gqlgen";
import Routes from "@/enums/routes";
import LoaderBox from "components/LoaderBox";
import Image from "components/Image";
import NameGoal from "components/NameGoal";

export function Loader() {
  return <LoaderBox className="w-72 h-56 rounded-2xl" />;
}

type Props = {
  data: ProgramFeaturedFragment;
  className?: string;
};

export default function ProgramFeatured({ data, className }: Props) {
  return (
    <Link passHref href={Routes.PROGRAM.replace(":slug", data.slug ?? "")}>
      <a
        className={classnames(
          className,
          "group block relative w-72 h-56 rounded-2xl overflow-hidden text-ink-background hover:text-ink-background-hover transition-all",
        )}
        aria-label="featured program"
      >
        <Image
          className="absolute inset-0 object-cover brightness-50 transition-all group-hover:scale-110 group-hover:brightness-75"
          imageId={data.image?.publicId}
          width={288}
          height={224}
          priority
        />

        <div className="bottom-left">
          <span className="text-alt font-bold text-4xl">{data.name}</span>
          <div>
            <span className="text-main text-sm font-lighter uppercase">
              <NameGoal code={data.goal?.code ?? null} />
            </span>
            &nbsp;·&nbsp;
            <span className="text-main font-bold">{data.durationWeeks}W</span>
          </div>
        </div>

        <FiArrowRight className="icon-base bottom-right" />
      </a>
    </Link>
  );
}
