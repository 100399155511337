/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import classnames from "classnames";

import styles from "./SideScroll.module.css";

type Props = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  space?: string;
};

export default function SideScroll({ className, children, space = "space-x-3", ...rest }: Props) {
  return (
    <div
      className={classnames(
        "relative flex overflow-x-scroll px-4 py-2",
        space,
        styles["no-shrink"],
        className,
      )}
      {...rest}
    >
      {children}

      <div className="pr-[1px]" />
    </div>
  );
}
