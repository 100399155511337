import * as React from "react";
import { FiMenu } from "react-icons/fi";

import Sidebar from "@/modules/nav/components/Sidebar";

export default function Menu() {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleToggle = React.useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return (
    <>
      <button type="button" aria-label="open sidebar" onClick={handleToggle}>
        <FiMenu className="icon-base text-ink-product" />
      </button>

      <Sidebar open={open} onClose={handleToggle} />
    </>
  );
}
