import * as React from "react";
import { useRouter } from "next/router";
import { signIn, useSession } from "next-auth/react";

import Routes from "@/enums/routes";
import * as redirect from "@/modules/account/services/redirect";

export function useSessionAdmin() {
  const { data: session, status } = useSession({ required: true });

  React.useEffect(() => {
    if (session?.user != null && !session.user.isAdmin) {
      signIn().catch(() => {});
    }
  }, [session]);

  return { session, status };
}

export function useAuthChange(cb: (isAnon: boolean) => void) {
  const { status } = useSession();
  const [prev, setPrev] = React.useState(status);

  React.useEffect(() => {
    if (status === "loading") {
      return;
    }
    if (prev !== "loading" && prev !== status) {
      // We ain't doing Node callbacks here
      // eslint-disable-next-line node/no-callback-literal
      cb(status === "unauthenticated");
    }
    setPrev(status);
    // Arg `cb` is missing on purpose to allow lambdas as args
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev, status]);
}

export const useRedirectUser = () => {
  const { status } = useSession();
  const { replace } = useRouter();

  React.useEffect(() => {
    if (status === "authenticated") {
      const url = redirect.consume();

      replace(url ?? Routes.ACCOUNT).catch(() => {});
    }
  }, [replace, status]);
};
