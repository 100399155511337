const AUTH_REDIRECT = "authRedirect";

export const save = (token: string) => localStorage.setItem(AUTH_REDIRECT, token);

const load = () => localStorage.getItem(AUTH_REDIRECT);

const remove = () => localStorage.removeItem(AUTH_REDIRECT);

export const consume = () => {
  const url = load();

  remove();

  return url;
};
