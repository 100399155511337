import * as React from "react";
import { FiUser } from "react-icons/fi";
import classnames from "classnames";

import Image from "components/Image";

const colors = {
  product: "border-ink-product hover:border-ink-product-hover text-ink-product",
  background: "border-ink-background hover:border-ink-background-hover text-ink-background",
};

export type Color = keyof typeof colors;

const sizes = {
  sm: "h-7 w-7",
  base: "h-10 w-10",
  lg: "h-16 w-16",
};

export type Size = keyof typeof sizes;

type Props = {
  className?: string;
  imageId?: string | null;
  color?: Color;
  size?: Size;
};

export default function ProfilePhoto({
  className,
  imageId = null,
  color = "product",
  size = "base",
}: Props) {
  return (
    <div
      className={classnames(
        "relative flex items-center justify-center rounded-full border-2",
        colors[color],
        sizes[size],
        className,
      )}
    >
      <div className="h-full w-full border-2 border-transparent rounded-full overflow-hidden">
        {imageId === null ? (
          <FiUser className="h-full w-full" />
        ) : (
          <Image imageId={imageId} height={64} width={64} />
        )}
      </div>
    </div>
  );
}
