import * as React from "react";
import { useSession } from "next-auth/react";

import type { Color } from "components/ProfilePhoto";
import ProfilePhoto from "components/ProfilePhoto";
import Link from "components/Link";
import Routes from "@/enums/routes";

type Props = {
  className?: string;
  color?: Color;
};

export default function BadgeProfile({ className, color }: Props) {
  const { data: session } = useSession();

  return (
    <Link
      className={className}
      aria-label={session?.user == null ? "sign in" : "view account"}
      href={session?.user == null ? Routes.AUTH_SIGN_IN : Routes.ACCOUNT}
    >
      <ProfilePhoto imageId={session?.user.image} color={color} size="sm" />
    </Link>
  );
}
