/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useIntl } from "react-intl";
import Link from "next/link";
import classnames from "classnames";

import type { HashtagFragment } from "@/gqlgen";
import LoaderBox from "components/LoaderBox";
import type Category from "@/enums/category";
import { messages } from "@/enums/category";
import Routes from "@/enums/routes";
import Query from "@/enums/query";

export function Loader() {
  return <LoaderBox className="h-8 w-40 rounded-lg" />;
}

type Props = {
  className?: string;
  data: HashtagFragment;
};

export default function Hashtag({ className, data }: Props) {
  const intl = useIntl();

  const name = React.useMemo(() => {
    const name =
      data.code in messages ? intl.formatMessage(messages[data.code as Category]) : data.code;

    return name.replace(/\s/g, "");
  }, [intl, data.code]);

  const url = React.useMemo(
    () => ({
      pathname: Routes.SEARCH,
      query: { [Query.CATEGORY]: data.code },
    }),
    [data.code],
  );

  return (
    <Link href={url} passHref>
      <a
        className={classnames(
          className,
          "inline-block font-alt font-bold text-2xl italic text-ink-product lowercase opacity-50 hover:opacity-100 transition-all",
        )}
      >
        #{name}
      </a>
    </Link>
  );
}
