import * as React from "react";
import { FiArrowRight } from "react-icons/fi";
import classnames from "classnames";

import type { ProgramProgressFragment } from "@/gqlgen";

type Props = {
  className?: string;
  data: ProgramProgressFragment;
};

export default function Progress({ className, data }: Props) {
  return (
    <div
      className={classnames(
        className,
        "relative flex w-full h-10 px-4 pt-0.5 justify-between items-center bg-product",
      )}
    >
      <div className="absolute top-0 left-0 h-0.5 w-full bg-ink-background-variant" />
      <div
        className="absolute top-0 left-0 h-0.5 bg-secondary"
        style={{ width: `${data.progressPercent ?? 0}%` }}
      />

      <div className="font-alt font-bold uppercase text-ink-product leading-loose">
        {data.progressPercent ?? 0}%
      </div>

      <FiArrowRight className="icon-sm text-ink-product" />
    </div>
  );
}
