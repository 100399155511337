/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Link from "next/link";
import classnames from "classnames";

import type { ProgramResultFragment } from "@/gqlgen";
// import ProfilePhoto from "components/ProfilePhoto";
import NameGoal from "components/NameGoal";
import Routes from "@/enums/routes";
import Image from "components/Image";
import Difficulty from "components/Difficulty";
import Progress from "components/Progress";
import type DifficultyEnum from "@/enums/difficulty";
import LoaderBox from "components/LoaderBox";
import Query from "@/enums/query";

export function Loader() {
  return <LoaderBox className="h-48 w-full rounded-2xl" />;
}

type Props = {
  className?: string;
  data: ProgramResultFragment;
};

function ProgramResult({ className, data }: Props) {
  const url = React.useMemo(() => {
    if (data.isEnrolled !== true) {
      return Routes.PROGRAM.replace(":slug", data.slug ?? "");
    }

    return {
      pathname: Routes.TRAIN.replace(":slug", data.slug ?? ""),
      query: {
        [Query.BLOCK]: data.nextBlock?.id ?? null,
        [Query.WEEK]: data.nextWeek?.id ?? null,
        [Query.SESSION]: data.nextSession?.id ?? null,
      },
    };
  }, [data]);

  return (
    <Link href={url} passHref>
      <a
        className={classnames(
          className,
          "group block relative h-48 w-full max-w-[32rem] rounded-2xl overflow-hidden flex flex-col text-ink-background hover:text-ink-background-hover",
        )}
        aria-label="program result"
      >
        <div className="relative w-full h-full">
          <div className="absolute inset-0">
            <div className="relative w-full h-full">
              <Image
                className="object-cover transition-all brightness-50 group-hover:scale-110 group-hover:brightness-75"
                imageId={data.image?.publicId}
                layout="fill"
                priority
              />
            </div>
          </div>

          <div className="top-left font-main">
            <div>
              <span className="font-lighter">
                <NameGoal code={data.goal?.code ?? null} />
                &nbsp;·&nbsp;
              </span>
              <span className="font-bold">{data.durationWeeks}W</span>
            </div>
            <Difficulty value={data.difficulty?.code as DifficultyEnum} />
          </div>

          <div className="bottom-left space-x-3 flex items-center">
            {/* <ProfilePhoto imageId={data.trainer?.image?.publicId} />*/}
            <div className="text-alt text-3xl font-bold">{data.name}</div>
            {/* <div>*/}
            {/*  <Text fontSize="14px" fontWeight="lighter">*/}
            {/*    <FormattedMessage*/}
            {/*      */}
            {/*      defaultMessage="By {name} {surname}"*/}
            {/*      values={{*/}
            {/*        name: (*/}
            {/*          <Text transform="uppercase" fontWeight="lighter">*/}
            {/*            {data.trainer?.name}*/}
            {/*          </Text>*/}
            {/*        ),*/}
            {/*        surname: (*/}
            {/*          <Text transform="uppercase" fontWeight="bold">*/}
            {/*            {data.trainer?.surname}*/}
            {/*          </Text>*/}
            {/*        ),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Text>*/}
            {/* </div>*/}
          </div>
        </div>

        {data.isEnrolled === true && <Progress data={data} />}
      </a>
    </Link>
  );
}

export default ProgramResult;
