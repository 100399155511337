import { defineMessages } from "react-intl";

enum Action {
  SEARCH = "search",
}

export default Action;

export const messages = defineMessages({
  [Action.SEARCH]: {
    defaultMessage: "Search",
  },
});
