import * as React from "react";
import classnames from "classnames";

import DifficultyEnum from "@/enums/difficulty";

type Props = {
  value: DifficultyEnum;
};

export default function Difficulty({ value }: Props) {
  const className = "h-2 w-2 rounded-full shadow";

  return (
    <div className="flex space-x-1 my-1">
      {value === DifficultyEnum.BEGINNER && (
        <>
          <div className={classnames(className, "bg-product")} />
          <div className={classnames(className, "bg-ink-background opacity-50")} />
          <div className={classnames(className, "bg-ink-background opacity-50")} />
        </>
      )}
      {value === DifficultyEnum.INTERMEDIATE && (
        <>
          <div className={classnames(className, "bg-product")} />
          <div className={classnames(className, "bg-product")} />
          <div className={classnames(className, "bg-ink-background opacity-50")} />
        </>
      )}
      {value === DifficultyEnum.ADVANCED && (
        <>
          <div className={classnames(className, "bg-product")} />
          <div className={classnames(className, "bg-product")} />
          <div className={classnames(className, "bg-product")} />
        </>
      )}
    </div>
  );
}
